<template>
  <div v-title data-title="Care for our Energy - Mega Energy">
    <div v-if="offsetwidth > 850">
      <!-- <img style="width: 100%" src="../assets/imgThree/PC/product.jpg" alt="" /> -->
      <img
        style="width: 100%"
        src="../assets/imgNew1/PC/top.png"
        alt=""
      />
      <div style="display: flex;width:100%">
        <img
          style="width: 100%"
          src="../assets/imgNew1/PC/left.png"
             @click="goac()"
          alt=""
        />
        <img
          style="width: 100%"
          src="../assets/imgNew1/PC/right.png"
       
          alt=""
        />
      </div>

      <div style="position: relative">
        <img
          style="width: 100%"
          src="../assets/imgNew1/PC/绿色背景.jpg"
          alt=""
        />

        <img
          style="
            width: 25%;
            position: absolute;
            top: 50%;
            left: 70%;
            cursor: pointer;
            transform: translate(-50%, -50%);
          "
          @click="goinfo"
          src="../assets/imgNew1/PC/按钮.png"
          alt=""
        />
      </div>
      <img
        style="width: 100%"
        src="../assets/imgNew1/PC/底部关于我们.jpg"
        alt=""
      />
    </div>
    <div v-else style="width: 100%">
      <!-- <img
        style="width: 100%"
        src="../assets/imgThree/H5/phoneProduct.jpg"
        alt=""
      /> -->
      <img
        style="width: 100%"
        src="../assets/imgNew1/Phone/top.png"
        alt=""
      />
       <img
        style="width: 100%"
        src="../assets/imgNew1/Phone/air.png"
        alt=""
           @click="goac()"
      />
       <img
        style="width: 100%"
        src="../assets/imgNew1/Phone/sp.png"
        alt=""
      />
      <!-- <img
        @click="goac()"
        style="width: 100%"
        src="../assets/imgNew1/Phone/product_bottom.jpg"
        alt=""
      /> -->

      <div style="position: relative">
        <img
          style="width: 100%"
          src="../assets/imgNew1/Phone/手机版绿色背景.jpg"
          alt=""
        />

        <img
          style="
            width: 60%;
            position: absolute;
            top: 60%;
            left: 50%;
            cursor: pointer;
            transform: translate(-50%, -50%);
          "
          @click="goinfo"
          src="../assets/imgNew1/Phone/按钮.png"
          alt=""
        />
      </div>
      <img
        style="width: 100%"
        src="../assets/imgNew1/Phone/手机版底部.jpg"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    offsetwidth: {
      type: Number,
    },
  },
  mounted() {
    // window.fbq("track", "ViewContent", {
    //   content_type: "Products",
    //   content_ids: ["10003"],
    //   content_name: "Products",
    //   content_category: "Products",
    // });
  },
  methods: {
    goac() {
      this.$router.push("/ProductsAc");
    },
    goinfo() {
      // window.sessionStorage.setItem("activeIndex", "/CustomerDetails");

      this.$emit("fun", "/CustomerDetails");
    },
  },
};
</script>

<style></style>
