<template>
  <div>
    <div v-title data-title="Care for our Energy - Mega Energy">
      <div v-if="offsetwidth > 850">
        <img
          style="width: 100%"
          src="../assets/imgNew1/PC/4相册.jpg"
          alt=""
        />
        <div style="position: relative">
          <img style="width: 100%" src="../assets/imgNew1/PC/绿色背景.jpg" alt="" />

          <img
            style="
             width: 25%;
            position: absolute;
            top: 50%;
            left: 70%;
            cursor: pointer;
            transform: translate(-50%, -50%);
            "
            @click="goinfo"
          src="../assets/imgNew1/PC/按钮.png"
            alt=""
          />
        </div>
        <img style="width: 100%" src="../assets/imgNew1/PC/底部关于我们.jpg" alt="" />
      </div>
      <div v-else style="width: 100%">
        <img
          style="width: 100%"
          src="../assets/imgNew1/Phone/4相册手机版.jpg"
          alt=""
        />
         <div style="position: relative">
        <img style="width: 100%" src="../assets/imgNew1/Phone/手机版绿色背景.jpg" alt="" />

        <img
          style="
            width: 60%;
            position: absolute;
            top: 60%;
            left: 50%;
            cursor: pointer;
            transform: translate(-50%, -50%);
          "
          @click="goinfo"
         src="../assets/imgNew1/Phone/按钮.png"
          alt=""
        />
      </div>
      <img style="width: 100%" src="../assets/imgNew1/Phone/手机版底部.jpg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    offsetwidth: {
      type: Number,
    },
  },
  methods: {
    goinfo() {
      // window.sessionStorage.setItem("activeIndex", "/CustomerDetails");

      this.$emit("fun", "/gellery");
    },
  },
};
</script>

<style></style>
