<template>
  <div v-title data-title="Care for our Energy - Mega Energy">
    <div v-if="offsetwidth > 850">
      <img style="width: 100%" src="../assets/imgNew1/PC/首页.jpg" alt="" />
      <!-- <img style="width: 100%" src="../assets/imgNew1/PC/首页产品.jpg" alt="" /> -->
      <div style="position: relative">
        <img
          style="width: 100%"
          src="../assets/imgNew1/PC/绿色背景.jpg"
          alt=""
        />

        <img
          style="
            width: 25%;
            position: absolute;
            top: 50%;
            left: 70%;
            cursor: pointer;
            transform: translate(-50%, -50%);
          "
          @click="goinfo"
          src="../assets/imgNew1/PC/按钮.png"
          alt=""
        />
      </div>
      <img style="width: 100%" src="../assets/imgNew1/PC/底部关于我们.jpg" alt="" />
    </div>
    <div v-else style="width: 100%">
      <img style="width: 100%" src="../assets/imgNew1/Phone/home.jpg" alt="" />
      <!-- <img style="width: 100%" src="../assets/imgNew1/Phone/home2.jpg" alt="" /> -->
      <!-- <img style="width: 100%" src="../assets/imgNew1/Phone/home3.jpg" alt="" /> -->
      <div style="position: relative">
        <img style="width: 100%" src="../assets/imgNew1/Phone/手机版绿色背景.jpg" alt="" />

        <img
          style="
             width: 60%;
            position: absolute;
            top: 60%;
            left: 50%;
            cursor: pointer;
            transform: translate(-50%, -50%);
          "
          @click="goinfo"
         src="../assets/imgNew1/Phone/按钮.png"
          alt=""
        />
      </div>
      <img style="width: 100%" src="../assets/imgNew1/Phone/手机版底部.jpg" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    offsetwidth: {
      type: Number,
    },
  },
  mounted() {
    // window.fbq("track", "ViewContent", {
    //   content_type: "index",
    //   content_ids: ["10001"],
    //   content_name: "index",
    //   content_category: "index",
    // });
  },
  methods: {
    goinfo() {
      // window.sessionStorage.setItem("activeIndex", "/CustomerDetails");

      this.$emit("fun", "/CustomerDetails");
    },
  },
};
</script>

<style></style>
